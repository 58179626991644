var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-layout',[_c('v-container',{staticClass:"pb-0"},[_c('v-row',{staticClass:"my-5",attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('span',{staticClass:"home-title d-inline-block"},[_vm._v(" PGSLOT ")])]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"float-right",attrs:{"text":"","rounded":"","color":"pink","href":_vm.generateGameLobbyUrl('26'),"target":"_blank"}},[_vm._v(" ดูเกมส์ทั้งหมด ")])],1)],1),_c('v-row',{attrs:{"justify":"center","no-gutters":""}},[_c('base-slider',{staticClass:"base-slider--pgslot"},_vm._l((_vm.getPgGames()),function(game,index){return _c('a',{key:("slot-" + index),attrs:{"href":_vm.generatePgSlotUrl(game.provider, game.gameCode),"target":"_blank"}},[_c('img',{staticClass:"base-slider__item--image",staticStyle:{"width":"100px"},attrs:{"src":game.imageUrl,"alt":"slot"}})])}),0)],1),_c('v-divider',{staticClass:"mt-5"}),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('span',{staticClass:"home-title my-5 d-inline-block"},[_vm._v(" Dking Casino ")])]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"float-right",attrs:{"text":"","color":"pink","rounded":"","depressed":"","href":_vm.generateGameLobbyUrl('', 'casino'),"target":"_blank"},on:{"click":function($event){return _vm.updateRecentGame({
              type: 'casino',
              gameId: '',
              imageUrl: (_vm.$assetsEndpoint + "/dking.png")
            })}}},[_vm._v(" ดูเกมส์ทั้งหมด ")])],1)],1),_c('v-row',{attrs:{"justify":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('base-slider',_vm._l((_vm.dkingGames),function(dkingGame){return _c('div',{key:("dking-" + (dkingGame.name)),staticClass:"game-item"},[_c('a',{attrs:{"href":_vm.generateGameLobbyUrl(_vm.getIdByGame(dkingGame.name), 'casino'),"target":"_blank"},on:{"click":function($event){_vm.updateRecentGame({
                  type: 'casino',
                  gameId: _vm.getIdByGame(dkingGame.name),
                  imageUrl: (_vm.$assetsEndpoint + "/" + (dkingGame.image) + ".png")
                })}}},[_c('img',{staticClass:"game-item__image",attrs:{"src":(_vm.$assetsEndpoint + "/" + (dkingGame.image) + ".png"),"alt":"image","width":"100%","height":"100%"}})])])}),0)],1)],1),_c('v-divider',{staticClass:"mt-5"}),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('span',{staticClass:"home-title my-5 d-inline-block"},[_vm._v(" Bikini Game ")])]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"float-right",attrs:{"color":"pink","text":"","rounded":"","depressed":"","href":_vm.generateGameLobbyUrl('', 'bikini'),"target":"_blank"},on:{"click":function($event){return _vm.updateRecentGame({
              type: 'bikini',
              gameId: '',
              imageUrl: (_vm.$assetsEndpoint + "/bikini.png")
            })}}},[_vm._v(" ดูเกมส์ทั้งหมด ")])],1)],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('base-slider',_vm._l((_vm.bikiniGames),function(bikiniGame){return _c('div',{key:("bikini-" + (bikiniGame.name)),staticClass:"game-item"},[_c('a',{attrs:{"href":_vm.generateGameLobbyUrl(_vm.getIdByGame(bikiniGame.name), 'bikini'),"target":"_blank"},on:{"click":function($event){_vm.updateRecentGame({
                  type: 'bikini',
                  gameId: _vm.getIdByGame(bikiniGame.name),
                  imageUrl: (_vm.$assetsEndpoint + "/" + (bikiniGame.image) + ".png")
                })}}},[_c('img',{staticClass:"base-slider__item--image",attrs:{"src":(_vm.$assetsEndpoint + "/" + (bikiniGame.image) + ".png"),"alt":"image"}})])])}),0)],1)],1),_c('v-divider',{staticClass:"mt-5"}),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('span',{staticClass:"home-title my-5 d-inline-block"},[_vm._v(" BetWorld ")])]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"float-right",attrs:{"text":"","color":"pink","rounded":"","depressed":"","href":_vm.generateBetworldUrl(),"target":"_blank"},on:{"click":function($event){return _vm.updateRecentGame({
              type: 'betworld',
              gameId: '',
              imageUrl: (_vm.$assetsEndpoint + "/betworld.png")
            })}}},[_vm._v(" ดูเกมส์ทั้งหมด ")])],1)],1),_c('v-row',{attrs:{"justify":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('base-slider',_vm._l((_vm.betworldGames),function(betworld){return _c('div',{key:("betworld-" + (betworld.name)),staticClass:"game-item"},[_c('a',{attrs:{"href":_vm.generateBetworldUrl(),"target":"_blank"},on:{"click":function($event){return _vm.updateRecentGame({
                  type: 'betworld',
                  gameId: '',
                  imageUrl: (_vm.$assetsEndpoint + "/betworld.png")
                })}}},[_c('img',{staticClass:"game-item__image",attrs:{"src":(_vm.$assetsEndpoint + "/" + (betworld.image) + ".png"),"alt":"image","width":"100%","height":"100%"}})])])}),0)],1)],1),_c('v-divider',{staticClass:"mt-5"}),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('span',{staticClass:"home-title my-5 d-inline-block"},[_vm._v(" Royal ")])]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"float-right",attrs:{"text":"","color":"pink","rounded":"","depressed":"","href":_vm.generateRoyal(),"target":"_blank"},on:{"click":function($event){return _vm.updateRecentGame({
              type: 'royal',
              gameId: '',
              imageUrl: (_vm.$assetsEndpoint + "/royal.png")
            })}}},[_vm._v(" ดูเกมส์ทั้งหมด ")])],1)],1),_c('v-row',{attrs:{"justify":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('base-slider',_vm._l((_vm.royalGames),function(royal){return _c('div',{key:("royal-" + (royal.name)),staticClass:"game-item"},[_c('a',{attrs:{"href":_vm.generateBetworldUrl(),"target":"_blank"},on:{"click":function($event){return _vm.updateRecentGame({
                  type: 'royal',
                  gameId: '',
                  imageUrl: (_vm.$assetsEndpoint + "/royal.png")
                })}}},[_c('img',{staticClass:"game-item__image",attrs:{"src":(_vm.$assetsEndpoint + "/" + (royal.image) + ".png"),"alt":"image","width":"100%","height":"100%"}})])])}),0)],1)],1),_c('v-divider',{staticClass:"mt-5"}),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('span',{staticClass:"home-title my-5 d-inline-block"},[_vm._v(" ยิงปลา ")])])],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('base-slider',_vm._l((_vm.studios),function(studio){return _c('div',{key:("studio-" + studio),staticClass:"game-item"},[_c('a',{attrs:{"href":_vm.generateFishGameUrl(),"target":"_blank"},on:{"click":function($event){return _vm.updateRecentGame({
                  type: 'fishing',
                  gameId: '50',
                  imageUrl: (_vm.$assetsEndpoint + "/" + studio + ".png")
                })}}},[_c('img',{staticClass:"game-item__image",attrs:{"src":(_vm.$assetsEndpoint + "/" + studio + ".png"),"alt":"image","height":"100%"}})])])}),0)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }