















































































































































































































































































































































































































































































































































// eslint-disable-next-line import/no-cycle
import LaunchGameMixin from '@/mixins/launchGameMixin';
import { Mixins, Component } from 'vue-mixin-decorator';
import BaseSlider from '@/components/BaseSlider.vue';

@Component({
  components: {
    BaseSlider,
  },
})
// eslint-disable-next-line max-len
export default class GameList extends Mixins<LaunchGameMixin>(LaunchGameMixin) {
  // public gameUrl: string = '';

  // eslint-disable-next-line class-methods-use-this
  // async created() {
  //   const data = await this.luanchGame();
  //   this.gameUrl = data || '';
  // }

  // eslint-disable-next-line class-methods-use-this
  get dkingGames() {
    return [
      { name: 'baccarat', image: 'baccarat' },
      { name: 'dragon tiger', image: 'dragon-tiger' },
      { name: 'hilo', image: 'hilo' },
      { name: 'sicbo', image: 'sicbo' },
      { name: 'roulette', image: 'roulette' },
    ];
  }

  // eslint-disable-next-line class-methods-use-this
  get bikiniGames() {
    return [
      { name: 'baccarat', image: 'bikini-baccarat' },
      { name: 'dragon tiger', image: 'bikini-dragon-tiger' },
      { name: 'hilo', image: 'bikini-hilo' },
      { name: 'sicbo', image: 'bikini-sicbo' },
      { name: 'roulette', image: 'bikini-roulette' },
    ];
  }

  // eslint-disable-next-line class-methods-use-this
  get betworldGames() {
    return [
      { name: 'baccarat', image: 'bet-world-baccarat' },
      { name: 'dragon tiger', image: 'bet-world-dragon-tiger' },
      { name: 'sicbo', image: 'bet-world-sicbo' },
      { name: 'roulette', image: 'bet-world-roulette' },
    ];
  }

  // eslint-disable-next-line class-methods-use-this
  get royalGames() {
    return [
      { name: 'baccarat', image: 'royal-baccarat' },
      { name: 'dragon tiger', image: 'royal-dragon-tiger' },
      { name: 'sicbo', image: 'royal-sicbo' },
      { name: 'roulette', image: 'royal-roulette' },
    ];
  }

  // eslint-disable-next-line class-methods-use-this
  get slots(): Array<string> {
    return [
      'queen-banquet',
      'butterfly-blossom',
      'fortune-ox',
      'caishen-wins',
      'lucky-neko',
      'roma',
      'wild-bandito',
    ];
  }

  // eslint-disable-next-line class-methods-use-this
  get studios() {
    return ['fishing'];
  }
}
